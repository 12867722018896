import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import Header from '../components/User/Header'
import SearchBar from '../components/User/SearchBar'
import CategorySlide from '../components/User/CategorySlide'
import Footer from '../components/User/Footer'
import HomeBox from '../components/User/HomeBox'
import TopFeed from '../components/User/TopFeed'
import apiInstance from '../api/index'
import { useDataLayerValue } from '../DataLayer'
import PreLoaderList from '../components/User/PreLoaderList'
import assistanceIcon from '../assets/img/icon.png'

function Index() {     
    
    document.title = 'Assistance - Helping others matters';

    const [posts, setPosts] = useState([]);
    const [topFeeds, setTopFeeds] = useState([]);    

    const [{ categories }] = useDataLayerValue();    

    useEffect(() => {                
        async function getPosts() {
            apiInstance.get("/homepage/feedData")       
            .then(response => {                                
                setPosts(flattenPosts(response.data.data));
                // console.log(flattenPosts(response.data.data))
            })
            .catch(e => {                
                console.log(e);                
                // message.error("Internal Server Error");
                // history.push('/fallback')
            });
        }
        getPosts();
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
       async function getRandomFeed() {
        apiInstance.get("/homepage/feed/random")       
        .then(response => {                
            setTopFeeds(response.data.data);
        })
        .catch(e => {                
            console.log(e);                
            // message.error("Internal Server Error");
            // history.push('/fallback');
        });
       }

       getRandomFeed(); 
       // eslint-disable-next-line react-hooks/exhaustive-deps           
    },[]);

    const flattenPosts = (data) => {
        let result = [];
        // eslint-disable-next-line
        data.map(item => {               
            if(item && item?.feed_type !== 'Internal Blog') {
                // eslint-disable-next-line
                item.items.map(i => {
                    if(i) {
                        i.rawCategory = item.feed.rawCategory;
                        i.rawFeedTitle = item.feed.title;
                        i.rawImage = item.feed.rawImage;
                        i.rawFeedUrl = item.feed.feedUrl;
                    }
                });
                result = [...result, ...flattenArray(item.items)]
            } else {
                if(item) {
                    console.log(item);
                    result.unshift(item);
                }
            }       
        });              
        return result;
    }

    const flattenArray = (arr) => {
        return arr.reduce(function(a, b) {
            if(Array.isArray(b)) {
                return a.concat(flattenArray(b));
            }
            return a.concat(b);
        }, [])
    }
    
    return (
        <div className="w-full bg-white">            
            <Header />
            <div className="w-full flex lg:flex-row flex-col justify-evenly lg:px-12 py-8">
                <div className="lg:hidden md:hidden w-full px-4 mb-8">
                    <SearchBar />
                </div>               
                <div className="w-full flex flex-col items-center h-full">
                    {
                        !!topFeeds.length && !!categories.length ?
                        <TopFeed data={topFeeds.filter(feed => !!feed)} /> :
                        (
                            <Skeleton loading={true} active className="lg:px-16 border-b pb-3 mb-4">
                                    <div>
                                        <h4>Assistace.org Sample Title</h4>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
                            </Skeleton>
                        )
                    }
                    <div className="w-full lg:hidden md:hidden px-4 mt-3 mb-8">
                        <CategorySlide />
                    </div>                    
                    {
                        !!posts.length ?
                            posts.filter(post => !!post).map((post, index) => (
                            categories.length && (
                                <HomeBox
                                    subTitle={post?.rawFeedTitle}
                                    icon={post?.rawImage||assistanceIcon}                                    
                                    url={post?.link||post.rawFeedUrl}
                                    created={post?.pubDate||post?.isoDate||null}
                                    key={index}
                                    title={post?.title}
                                    category={categories.filter(c => c.id === post?.rawCategory)[0]?.title || 'Assistance Blogs'}
                                    creator={post?.creator || post?.author || post?.rawFeedTitle || 'assistance'}                                
                                    description={post?.feed_type === 'Internal Blog' ? post?.description : post?.content || post?.description || post.contentSnippet || null}
                                    image={post?.image ?`data:image/jpeg;base64,${post?.image}` : null}
                                />   
                            )
                            )) :
                            (                                                                
                                <PreLoaderList />                                
                            )
                    }                                                                               
                </div>
                <div className="w-1/3 flex-col items-center h-full sticky top-0 hidden lg:flex md:flex mx-4">
                    <SearchBar 
                        placeholderText="What you need assistance for ?"                        
                    />
                    <CategorySlide />                    
                    <Footer />
                </div>
                <div className="lg:hidden md:hidden w-full px-4 mb-8">
                    <Footer />
                </div>  
            </div>
        </div>
    )
}

export default Index
