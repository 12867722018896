import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Form, Button, Input, message, Modal } from 'antd'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDataLayerValue } from '../../DataLayer'
import apiInstance from "../../api"
import { validateEmail } from '../../utils/Helper'

const { Text } = Typography;
const { confirm } = Modal;

function Account() {      

    // USER DATA FROM THE DATA LAYER !!!
    const [{ user }, dispatch] = useDataLayerValue();

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');    
    const [passwordLoad, setPasswordLoad] = useState(false);
    const [dataLoad, setDataLoad] = useState(false);
    const [closeLoad, setCloseLoad] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        // on component load the default data to the inputs
        setUsername(user?.username||'');
        setEmail(user?.email_address||'');                      
    },[user]);    

    /**
     * Used to update the user data
     * based on the given details
     */
    const handleUserDataChange = () => {
        if(username && email) {
            if(validateEmail(email)) {

                setDataLoad(true);
                let userPayload = {
                    username,
                    email_address: email
                };
                apiInstance.put(`/person/${user.id}`, userPayload)
                .then(response => {
                    if(response.data.status) {
                        message.success(response.data.data);
                        let tempLocaler = JSON.parse(sessionStorage.getItem('assistance_user'));
                        if(tempLocaler) {
                            tempLocaler = {
                                ...tempLocaler,
                                username: userPayload.username,
                                email_address: userPayload.email_address
                            };
                        }
                        sessionStorage.setItem('assistance_user', JSON.stringify(tempLocaler));

                        dispatch({ // FIRE IT ON DATA LAYER FOR NOW !!
                            type: "UPDATE_USER"                        
                        });
                    }                
                    setDataLoad(false);
                }).catch(e => {
                    if(e.response || e.response.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
            } else message.warning('Invalid Email Address');
            
        } else message.warning('Username or Email cannot be empty');
    }

    /**
     * Used to change the password based on the given
     * details with validation of new passsword
     * @param {oldPassword, newPassword, confirmPassword} data 
     */
    const handlePasswordChange = () => {
        if(form.getFieldValue('newPassword') === form.getFieldValue('confirmPassword')) {
            // positive
            setPasswordLoad(true);
            let passwordPaylod = {
                password: form.getFieldValue('oldPassword'),                
                new_password: form.getFieldValue('newPassword')
            };            
            apiInstance.put(`/person/password/${user.id}`, passwordPaylod)
            .then(response => {
                if(response.data.status) {
                    message.success(response.data.data);
                } else message.warning(response.data?.data|| 'Something went wrong');
                setPasswordLoad(false);
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
    
            form.resetFields();
            
        } else message.warning('Password donot match');
    }


    function deactivateConfirmation() {
        confirm({
          okButtonProps: {
              loading: closeLoad
          },
          title: 'Do you want to deactivate your account?',
          icon: <ExclamationCircleOutlined />,
          content: 'Once it is deactivated, it cannot be used anymore',
          onOk() {
            setCloseLoad(true);

            apiInstance.put(`/person/delete/${user.id}`)
            .then(response => {
                if(response.data.status) {                 
                    sessionStorage.clear();   
                    setCloseLoad(false);
                    window.location.replace("/admin");
                    message.success('Account Deactivated');
                } else message.warning(response.data?.data|| 'Something went wrong');
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
    

          },
          onCancel() {
            message.warning('Deactivation cancelled');
          },
        });
    }

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold mb-4"
            >My Account
            </h1>        
            <Row className="w-full" gutter={[16,16]}>            
                <Col span={12}>
                    <p
                        className="py-2 mb-2 border-b text-md font-bold"
                    >Personal Information                
                    </p>
                    <div className="flex flex-col w-1/2">

                        <p 
                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                        >Username :                     
                        </p>
                        <Text                                                     
                            editable={{ onChange: setUsername, autoSize: { minRows: 1, maxRows: 1 } }}
                            className="mb-2 mx-4"
                        >{username}                            
                        </Text>
                        <p 
                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                        >Email Address :                     
                        </p>
                        <Text                             
                            editable={{ onChange: setEmail, autoSize: { minRows: 1, maxRows: 1 } }}
                            className="mb-2 mx-4"
                        >{email}                            
                        </Text>    
                        <p 
                            className="text-md text-left py-1 font-semibold text-gray-700 mb-1"
                        >Joined Assistance :                     
                        </p>                
                        <p 
                            className="text-sm text-left py-1 mb-1 mx-4"
                        >{moment(user.created_at).format('MMM YYYY')}
                        </p>
                        <Button
                            disabled={(username.length===0) || (email.length===0)}
                            onClick={handleUserDataChange}
                            loading={dataLoad}
                            className="my-2"
                            style={{ width: 80 }}
                            type="primary"                            
                        >Save                        
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <p
                        className="py-2 mb-6 border-b text-md font-bold"
                    >Account Security
                    </p>
                    <Form
                        className="w-1/2"
                        layout="vertical"
                        form={form}
                        onFinish={handlePasswordChange}
                    >
                        <Form.Item name="oldPassword">
                            <Input.Password 
                                required                                
                                placeholder="Old Password" 
                            />
                        </Form.Item>
                        <Form.Item name="newPassword">
                            <Input.Password 
                                required                                    
                                placeholder="New Password" 
                            />
                        </Form.Item>
                        <Form.Item name="confirmPassword">
                            <Input.Password 
                                required                                
                                placeholder="Confirm New Password" 
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                loading={passwordLoad}                                
                                type="primary"
                            >Change Password                                
                            </Button>
                        </Form.Item>
                    </Form>
                    <p
                        className="border-b text-md font-bold"
                    >Suspend your account
                    </p>
                    <p
                        className="py-1 w-full text-left text-xs mb-2 text-gray-600"
                    > Once you deactivate your account you use it again until it is allowed by admin
                    </p>
                    <Button
                        onClick={deactivateConfirmation}
                        type="danger"
                    >Deactivate Account
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Account
