import React, { useEffect, useState } from 'react'
import '../../stylesheets/HomeBox.css'
import moment from 'moment'
import assistanceIcon from '../../assets/img/icon.png'
import { validateURL } from '../../utils/Helper'

function HomeBox({ title, category, description, created, image, creator, url, icon, subTitle }) {       

    const [postImage, setPostImage] = useState(image);
    const [postTitle, setPostTitle] = useState('');
    const [postVideo, setPostVideo] = useState(null);
    const [postDescription, setPostDescription] = useState(description);

    useEffect(() => {

        if(typeof title === 'object') {
            if (title.hasOwnProperty('a')) {
                if (title['a']?.length) {
                    if (title['a'][0]?.hasOwnProperty('_')) {
                        setPostTitle(title['a'][0]['_']);
                    } 
                } 
            } 
        } else setPostTitle(title);

        let linkContainer = document.getElementById('homeBox');
        let links = linkContainer.getElementsByTagName('a');
        for (var i=0; i<links.length; i++) {
            links[i].target = '_blank';
        }        
    },[title]);    

    useEffect(() => {        
        let doc = new DOMParser().parseFromString(description, "text/html");
        setPostImage(doc.getElementsByTagName('img')[0]?.src||image);
        setPostVideo(doc.getElementsByTagName('video')[0]?.src||null);

        if(description) {
            let imgRemovedDescription = description.replace(/<img[^>]*>/g,"");
            let videoRemovedDescription = imgRemovedDescription.replace(/<video[^>]*>/g,"")
            setPostDescription(videoRemovedDescription);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps 
    },[description]);

    return (
        <div id="homeBox" className="w-full flex items-start justify-between lg:px-10 py-4">
            <div className={`flex items-start flex-col mx-4 lg:pb-6 lg:pt-2`}>
                <div className="flex items-center">
                    <img
                        className="h-4 rounded"
                        src={validateURL(icon) ? icon : assistanceIcon}
                        alt="icon"
                    />
                    <p className="w-full font-semibold lg:text-sm text-xs mx-2 topFeedBox__title"
                    >{subTitle || category}                 
                    </p>
                </div>
                <a 
                    rel="noopener noreferrer"
                    target="_blank"
                    href={url}
                    className="font-bold hover:underline text-base lg:text-xl primary-color cursor-pointer">
                    {postTitle}
                </a>
                <p 
                    className="text-gray-600 lg:block hidden text-md py-1 homeBox__description"
                    dangerouslySetInnerHTML={{ __html: postDescription }}
                ></p>
                <div className="flex items-center">
                    {
                        !!created && (
                            <p className="text-gray-600 lg:text-md text-xs py-1">
                                {moment(created).isValid() ? moment(created).format('MMM DD') : ''}
                            </p>
                        )                    
                    }
                    <span className="px-2">·</span>
                    <p className="text-gray-600 lg:text-md text-xs py-1">
                        {creator}
                    </p>
                </div>
            </div>
            {
                (!!postImage || !!image) && (
                    <div className="homeBox__hoverImage h-24 lg:h-32 lg:mr-0 mr-2">
                        <img
                            className="object-contain"
                            src={postImage || image}
                            alt=""
                        />
                    </div>
                )
            }
            {
                !!postVideo && (
                    <video
                            className="object-contain"
                            src={postVideo}
                            controls={false}
                            alt="post"
                    ></video>
                )
            }
        </div>
    )
}

export default HomeBox
