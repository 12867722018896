import React from 'react'
import { Link } from 'react-router-dom'
import '../../stylesheets/ListMenu.css'
import assistanceIcon from '../../assets/img/icon.png'

function ListMenu({ urlKey, id, title, image=null, description=null, type=null, feedType=null, url=null }) {
    return (
        <>
        {
            (type === 'multi' || feedType === 'RSS Feed') ? (
                <Link 
                    to={type==='multi'?`/category/${id}`:feedType==='RSS Feed'?`/${urlKey}`:'/'}
                    className={`w-full flex items-start shadow border rounded-md ${type==='multi'?'listMenu__banner p-6':'bg-white p-4'}`}>           
                    {                        
                            <img
                                src={image||assistanceIcon}
                                alt="feedmenu"
                                className="mx-2 h-16 w-16 rounded-md"
                                style={{                    
                                    objectFit: 'contain'
                                }}                     
                            />                        
                    }
                    <div className={`flex flex-col items-start text-left ml-2`}>
                        <h4 className={`max-w-xs lg:w-full font-semibold text-left listMenu__title ${type==='multi'?'text-white text-lg':'primary-color text-md'}`}>
                            {title}
                        </h4>
                        {
                            !!description && (
                                <p className="text-gray-700 text-xs py-1 text-left topFeedBox__title">
                                    {description}
                                </p>
                            )
                        }
                    </div>
                </Link>
            ) : (
                <a
                    rel="noopener noreferrer"
                    target={feedType==='Internal Blog'?'_self':'_blank'}
                    href={url||`/${urlKey}`}
                    className={`w-full flex items-start shadow border rounded-md ${type==='multi'?'listMenu__banner p-6':'bg-white p-4'}`}>           
                    {                        
                            <img
                                src={image||assistanceIcon}
                                alt="feedmenu"
                                className="mx-2 h-16 w-16 rounded-md"
                                style={{                    
                                    objectFit: 'contain'
                                }}
                            />                     
                    }
                    <div className={`flex flex-col items-start text-left ml-2`}>
                        <h4 className={`max-w-xs lg:w-full font-semibold text-left listMenu__title ${type==='multi'?'text-white text-lg':'primary-color text-md'}`}>
                            {title}
                        </h4>
                        {
                            !!description && (
                                <p className="text-gray-700 text-xs py-1 text-left topFeedBox__title">
                                    {description}
                                </p>
                            )
                        }
                    </div>
                </a>
            )
        }
        </>
    )
}

export default ListMenu
