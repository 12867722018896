import React, { useState, useEffect } from 'react'
import { Modal, Input, Switch, message, Select, Spin } from 'antd'
import apiInstance from '../../api'
import { useDataLayerValue } from '../../DataLayer'
import { constructJSONMap, validateEmail } from '../../utils/Helper'

const { Option } = Select;

function UserModal({ modalTitle, okLabel, trigger, callTrigger = ct => ct, data=null, type }) {

    const [{ allRoles }] = useDataLayerValue(); // PULL FROM DATA LAYER

    const [myRoles, setMyRoles] = useState([]);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');    
    const [password, setPassword] = useState(''); 
    const [status, setStatus] = useState(false);    
    const [load, setLoad] = useState(false);

    const [rolesToAdd, setRolesToAdd] = useState([]);
    const [rolesToRemove, setRolesToRemove] = useState([]);
    
    const [oldUsername, setOldUsername] = useState('');
    const [oldEmail, setOldEmail] = useState('');
    const [oldStatus, setOldStatus] = useState(null);

    /**
     * Handling the positive action 
     * button of the modal
     */
    const handleOk = e => {        
        if(type === 'create') {
            if(username && email && password) {
                if(validateEmail(email)) {
                    setLoad(true);
                    let user = { // constructing the user
                        username,
                        password,
                        email_address: email
                    };            
                    apiInstance.post("/person/", user)
                    .then(response => {
                        if(response.data.data) {
                            setLoad(false);
                            callTrigger(false);
                            message.success('User Created');
                        } else message.warning(response.data?.data || 'something went wrong');
                    }).catch(e => {
                        if(e.response || e.response?.data) {                
                            message.error(e.response.data.message);
                            if(e.response.status === 401) {
                                sessionStorage.clear();
                                window.location.replace("/admin");
                            }
                        } else message.error("Internal Server Error");
                    });
                } else message.warning('Invalid Email Address')    
            }
        }  else if(type === 'update') {
            setLoad(true);
            let user = {status};            
            if(username) user = {...user, username};
            if(email) user = {...user, email_address: email};            
            if(password) user = {...user, password};            
            if(validateEmail(email)) {
                apiInstance.put(`/person/${data.id}`, user)
                .then(response => {
                    if(response.data.status) {
                        setLoad(false);
                        callTrigger(false);
                        message.success(response.data.data);
                    } else message.warning(response.data?.data || 'something went wrong');
                }).catch(e => {
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
            } else message.warning('Invalid Email Address');    
    
        } else if(type === 'assign_role') {

            if(rolesToAdd.length || rolesToRemove.length) { 
                                    
                        // CLEAR TO GO
                        if(rolesToAdd.length && rolesToRemove.length) {
                            setLoad(true);
                            // NEED TO PERFORM TWO OPERATIONS
                            // REMOVE ROLES AND ASSIGN SOME
                            let addRolesJSON = {
                                data: JSON.stringify(constructJSONMap(rolesToAdd, data.id, "rId", "pId"))
                            };
                            let removeRolesJSON = {
                                data: JSON.stringify(constructJSONMap(rolesToRemove, data.id, "rId", "pId"))
                            };            

                            // REMOVING THE ROLES REQUESTED
                            apiInstance.put("/person/remove", removeRolesJSON)
                            .then(preResult => {
                                if(preResult.data.status) {
                                    // ADDING THE ROLES REQUESTED
                                    apiInstance.post("/person/assign", addRolesJSON)
                                    .then(response => {
                                        if(response.data.status) {
                                            handleCancel();
                                            message.success("User Roles Modified");
                                        } else message.warning(response.data?.data || 'something went wrong');
                                    }).catch(e => {
                                        if(e.response || e.response?.data) {                
                                            message.error(e.response.data.message);
                                            if(e.response.status === 401) {
                                                sessionStorage.clear();
                                                window.location.replace("/admin");
                                            }
                                        } else message.error("Internal Server Error");
                                    });

                                } else message.warning(preResult.data?.data || 'something went wrong');
                            })
                            .catch(e => {
                                if(e.response || e.response?.data) {                
                                    message.error(e.response.data.message);
                                    if(e.response.status === 401) {
                                        sessionStorage.clear();
                                        window.location.replace("/admin");
                                    }
                                } else message.error("Internal Server Error");
                            });
                    
                            
                            setLoad(false);

                        } else if(rolesToAdd.length) {                            
                            setLoad(true);
                            // NEED TO ASSIGN ROLES                            
                            let addRolesJSON = {
                                data: JSON.stringify(constructJSONMap(rolesToAdd, data.id, "rId", "pId"))
                            }; 

                            apiInstance.post("/person/assign", addRolesJSON)
                            .then(response => {                                                                
                                if(response.data.status) {                                   
                                   message.success(response.data.data);
                                   handleCancel();                                   
                                } else message.warning(response.data?.data || 'something went wrong');
                            }).catch(e => {
                                console.log(e);
                                if(e.response || e.response?.data) {                
                                    message.error(e.response.data.message);
                                    if(e.response.status === 401) {
                                        sessionStorage.clear();
                                        window.location.replace("/admin");
                                    }
                                } else message.error("Internal Server Error");
                            });                                                                                                  

                            setLoad(false);

                        } else if(rolesToRemove.length) {
                            setLoad(true);
                            // NEED TO REMOVE ROLES
                            let removeRolesJSON = {
                                data: JSON.stringify(constructJSONMap(rolesToRemove, data.id, "rId", "pId"))
                            };

                            apiInstance.put("/person/remove", removeRolesJSON)
                            .then(response => {
                                if(response.data.status) {                                    
                                    message.success(response.data.data);
                                    handleCancel();
                                } else message.warning(response.data?.data || 'something went wrong');
                            }).catch(e => {
                                console.log(e);
                                if(e.response || e.response?.data) {                
                                    message.error(e.response.data.message);
                                    if(e.response.status === 401) {
                                        sessionStorage.clear();
                                        window.location.replace("/admin");
                                    }
                                } else message.error("Internal Server Error");
                            });
                                            
                            setLoad(false);

                    }                    

            } else message.warning('No changes detected!');

        }
    }  

    /**
     * Handling the negative action 
     * button of the modal
     */
    const handleCancel = e => {
        setStatus(false);
        setUsername('');
        setEmail('');
        setOldEmail('');
        setOldStatus(null);
        setOldUsername('');
        setRolesToAdd([]);
        setRolesToRemove([]);
        callTrigger(false);
    }  

    useEffect(() => {
        if(type === 'update') {            
            if(data) {
                setUsername(data?.username||'');
                setOldUsername(data?.username||'');
                setOldEmail(data?.email_address||'');
                setEmail(data?.email_address||'');        
                setStatus(data.is_active); 
                setOldStatus(data.is_active);
            }
        }     
        else if(type === 'assign_role') {            
            if(data) getRolesByPersonId(data.id); 
            setUsername(data?.username||'');
        }           
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[data]);          

    /**
     * Used to get all the roles
     * based on the person id
     * @param {id} id 
     */
    const getRolesByPersonId = async (id) => {
        setStatus(true);
        apiInstance.get(`/role/person/${id}`)
        .then(response => 
            {
                if(response.data.status) {
                    setMyRoles(response.data.data) 
                    setStatus(false);                     
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
    
    }
    
    const getIdByName = (data, type) => {
        let result = [];
        // eslint-disable-next-line
        allRoles.map(({ name, id }) => {
           if(type) {
               // eslint-disable-next-line
            data.map(i => {
                if(i === name) result.push(id.toString());
               });
            } else {
                // eslint-disable-next-line
                data.map((i) => {
                    if(i.roles === name) result.push(id.toString());
                   });
            }
        });
        return result;
    }
    
    const handleRoleChange = (data) => {
        let exisitingRoles = getIdByName(myRoles, false);
        let correctedRoles = getIdByName(data, true);                  
        setRolesToAdd(correctedRoles.filter(role => !exisitingRoles.includes(role)));        
        setRolesToRemove(exisitingRoles.filter(role => !correctedRoles.includes(role)));                
    }

    return (
        <Modal        
            maskClosable={false}                     
            destroyOnClose={true}
            title={modalTitle}
            visible={trigger}
            onOk={handleOk} 
            onCancel={handleCancel}           
            okText={okLabel}
            okButtonProps={{
                loading: load,
                disabled: (type==='update'?
                            ((oldUsername === username) && (oldEmail === email) && !password && (oldStatus === status)):
                            type === 'create' ?(!username || !password || !email):false)                            
            }}
        >            
            {
                type !== "assign_role" && data
                ?             
                <div>
                <Input                                                           
                    onChange={e => setUsername(e.target.value)}
                    value={username}                
                    placeholder="Username"
                    type="text"
                    className="mb-3"
                />
                <Input                                       
                    onChange={e => setEmail(e.target.value)}
                    value={email}                
                    placeholder="Email Address"
                    type="email"
                    className="mb-3"
                />
                <Input.Password                 
                    onChange={e => setPassword(e.target.value)}
                    value={password}                
                    type="password"
                    placeholder="Password"
                    className="mb-3"
                />
                </div> 
                :                 
                        !status && type === 'assign_role'?
                        <div>
                        <p className="text-left text-md text-gray-700 mb-2">
                            Roles are modified to the user: <strong>{username}</strong>
                        </p>
                        <Select                    
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Roles"    
                            notFoundContent="No Roles Present"   
                            defaultValue={Array.from(myRoles.map(role => role.roles))}                                         
                            onChange={handleRoleChange}
                        >                      
                        {
                            allRoles.map(role => (
                                <Option key={role.name}>{role.name}</Option>
                            ))
                        }
                        </Select> 
                        </div>
                    : <div className="w-full flex items-center justify-center"
                    ><Spin size="small" />
                    </div> 
            }
            {
                type === "update" &&
                data &&
                <div className="w-full flex items-center">
                    <p className="text-xs font-bold text-gray-600 py-2"
                    >Status
                    </p>
                    <Switch
                        className="mx-2"
                        size="small"          
                        onChange={(e) => {
                            setStatus(e);
                            setOldStatus(!e);
                        }}      
                        checked={status}
                    />
                </div>                
            }
        </Modal>
    )
}

export default UserModal
