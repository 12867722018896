
/**
 * Used to create a clear breadcrumb format
 * data using the given data and the target category
 * @param {*} data 
 * @param {*} targetCategory 
 */
export const constructBreadCrumb = (data, targetCategory) => {
    
    let result = []; // result category array from target to its top most root

    for (var category in data) {

        if(data[category].id === parseInt(targetCategory)) {
            result.push(data[category].title); // target category
            if (data[category].parent_id !== null) {

                // concats the old result array with the current returned category array
                result = [...result, ...constructBreadCrumb(data, data[category].parent_id)];

            }
        }
    }

    return result;
}

/**
     * Used to construct the required tree format
     * that is supported by the tree UI from the raw data
     * @param {data} data 
     * @param {null} parent 
*/
export const generateTreeData = (data, parent) => {

        let result = []; // result nested array

        for (var item in data) { // iterating through each category

             // add an extra key to the object for React unique props
            // eslint-disable-next-line
            if (data[item].parent_id == parent) { // partially checking the parent_id of item and current parent matches

                data[item].key = data[item].id;
                // sub item run
                // recursive call with the current parent item
                var children = generateTreeData(data, data[item].id);

                if(children.length) { // check for parent item with non empty sub item 
                    data[item].children = children;
                } else data[item].children = [];

                // parent item append
                result.push(data[item]);

            }

        }

        return result;
}

/**
 * Used to add a key to the given data object
 * and return the same object in order to acheive
 * react unique prop
 * @param {*} data
 * @param {string} key
 */
export const addKeyToData = (data, key) => {
    // eslint-disable-next-line
    data.map(item => {
        item.key = item[key];
    });    

}

/**
 * Used to construct the required format [{key:value, key:value}]
 * inorder to perform some mapping operations
 * @param {*} data 
 * @param {string} parentData 
 */
export const constructJSONMap = (data, parentData, dataKey, parentKey) => {
    let result = [];
    // eslint-disable-next-line
    data.map(item => {
        let tempJSON = {};
        tempJSON[dataKey] = parseInt(item);
        tempJSON[parentKey] = parseInt(parentData);
        result.push(tempJSON);
    });

    return result;
}

/**
 * Used to validate the input email address
 */
export const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
     * Used to build the permission required Name
     * format for viewing
     * @param {string} string 
     */
export const buildPermissionName = (string) => {
        let result = "";
        let stringArray = string.split(':');
        let firstWrd = stringArray[1].charAt(0).toUpperCase() + stringArray[1].slice(1);
        let secondWrd = stringArray[0].charAt(0).toUpperCase() + stringArray[0].slice(1);
        result = `${firstWrd} ${secondWrd}`;
        return result;
}

/**
 * Used to generate the reversed permission Name
 * like present in the db
 * @param {string} string 
 */
export const reversePermissionName = (string) => {
    let result = "";
    let stringArray = string.split(' ');
    let firstWrd = stringArray[1].charAt(0).toLowerCase() + stringArray[1].slice(1);
    let secondWrd = stringArray[0].charAt(0).toLowerCase() + stringArray[0].slice(1);
    result = `${firstWrd}:${secondWrd}`;
    return result;
}

/**
 * Used to split the url into different components
 * that are required and used whenever needed
 * @param {url} url 
 */
export const breakDownURL = (url) => {
    var domain = "",
        shortUrl = "",
        page = "";
    
        // removing the protocols
        if(url.indexOf("https://") === 0) url = url.substr(8);
        if(url.indexOf("http://") === 0) url = url.substr(7); 
        
        // removing the subdomains
        if(url.indexOf("www.") === 0) url = url.substr(4);

        domain = url.split('/')[0].split('.')[0];
        shortUrl = url.split('/')[0];

        if(url.split('/').length > 1) {
            page = url.split('/')[1].split('.')[0];
        }

        return { domain, page, shortUrl };
}

/**
 * Used to validate the given url 
 * is valid
 * @param {*} url 
 */
export const validateURL = (url) =>{
    var elm;
    if(url) {
        if(!elm){
            elm = document.createElement('input');
            elm.setAttribute('type', 'url');
          }
          elm.value = url;
          return elm.validity.valid;
    } else return false;
}