import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton, Tree } from 'antd'
import { useDataLayerValue } from '../../DataLayer'
import { generateTreeData } from '../../utils/Helper'

function CategorySlide({ boxTitle='Articles', data=null }) {

    let history = useHistory();
    const [{ categories }] = useDataLayerValue();  

    const [parentCategories, setParentCategories] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [viewToggle, setViewToggle] = useState(false);

    useEffect(() => {
        if(!data) {
            // categories
            const newParentCategories = categories.filter(c => (c.parent_id === null) && (parseInt(c.is_active) === 1));
            setParentCategories(newParentCategories);
        } else {
            // subcategories
            setParentCategories(data?.filter(item => (parseInt(item?.is_active||0) === 1))|| []);
        }
    },[categories, data]);

    useEffect(() => {        

        const filteredCategories = categories.filter(category => category?.is_active === 1);
        const treeData = generateTreeData(filteredCategories);                  
        setTreeData(treeData);        

    },[categories]);  

    /**
     * Used to get the current selected id 
     * of a category and into the state
     * @param {id} id 
     */
    const onSelect = (id)  => {        
        // setSelectedCategory(id[0]);
        // UPDATE THE SELECTED CATEGORY
        let item = id[0];
        let categoryTitle = categories.filter(category => category.id === item)[0]?.title;
        history.push(`/article/${categoryTitle}`);
    };

    return (
        <div className="w-full flex flex-col items-center p-6 bg-white border shadow lg:mt-5">
            <h5 className="text-lg font-bold text-left w-full font-bold mb-3 primary-color"
            >{boxTitle}                
            </h5>   
            <div className={`w-full flex flex-col items-center ${viewToggle?'overflow-x-hidden overflow-y-auto categorySlide':''}`}>
                {
                    !!parentCategories.length ? (
                        // parentCategories.slice(0, viewToggle?parentCategories.length:5).map(({ id, title }) => (
                        //     // <Link 
                        //     //     to={`/category/${id}`}
                        //     //     key={id} 
                        //     //     className="w-full primary-color border-b pb-2 border-gray-400 cursor-pointer py-4"
                        //     // >{title}                   
                        //     // </Link>
                            
                        // ))
                        <Tree                                  
                                // selectedKeys={[selectedCategory]}
                                className="w-full py-2"                                              
                                style={{ fontSize: 15 }}
                                draggable={false}
                                blockNode
                                onSelect={onSelect}                                
                                treeData={treeData.slice(0, viewToggle?parentCategories.length:5)}
                                height={400}
                        />
                    ) : (
                        <>
                            <Skeleton loading={true} active className="border-b mb-4">
                                <h4>Assistace.org Sample Title</h4>                                    
                            </Skeleton>                            
                        </>
                    )                    
                }                  
            </div>
            {
                (boxTitle === 'Articles'||boxTitle==='Articles') && !viewToggle && parentCategories.length > 5 && (
                    <p                                      
                        onClick={() => setViewToggle(!viewToggle)}          
                        className="primary-color cursor-pointer text-center w-full mt-4 font-semibold"
                    >View More               
                    </p>
                )
            }
        </div>
    )
}

export default CategorySlide
