import React,{ useState, useEffect } from 'react'
import { Button, Tree, Empty, message, Switch, Popconfirm } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { generateTreeData } from '../../utils/Helper'
import CategoryModal from './CategoryModal'
import { useDataLayerValue } from '../../DataLayer'
import apiInstance from '../../api'
import moment from 'moment'

function Category() {      
    const [treeData, setTreeData] = useState([]);    
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [load, setLoad] = useState(false);
        
    const [orderStatus, setOrderStatus] = useState('');
    // CATEGORY DATA FROM DATA LAYER !!!
    const [{ categories, selectedCategory, categoriesStatus }, dispatch] = useDataLayerValue();

    async function getAllCategories() {        
        apiInstance.get("/category")
        .then(response => {
          if(response.data.status) {
            // SHOOT DATA TO THE DATA LAYER         
            dispatch({
              type: 'SET_CATEGORIES',
              categories: response.data.data
            });
          } else message.warning(response.data?.data||'Something went wrong');          
          setOrderStatus(`Saved ${moment(new Date().toISOString()).fromNow()}`);
        })
        .catch(e => {
          console.log(e);
          if(e.response || e.response?.data) {                
              message.error(e.response.data.message);
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else message.error("Internal Server Error");
        });
    }
    

    useEffect(() => {        

        const treeData = generateTreeData(categories);        
        setTreeData(treeData);        

    },[categories]);      

    const validateOrder = (dropToGap, dragItem, dropItem) => {

        if (dropToGap) {

            if (dragItem.parent_id !== dropItem.parent_id) {
                updateOrder(dragItem.id, null);                
            } 

        } else {
            updateOrder(dragItem.id, dropItem.id); // UPDATING THE ORDER            
        }
    }

    /**
     * Used to handle any drop of category into any
     * other category
     * ** [This Piece of Code is from ANT DESIGN] **
     * @param {*} info 
     */
    const onDrop = (info) => {    
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        validateOrder(info.dropToGap, info.dragNode, info.node);

        /**
         * 1) dropToGap = true
         *    DONOT DO ANYTHING
         * 2) dropToGap = false
         *    dragKey-parentId = dropKey  
         * 3) dropToGap = true
         *    check parentId unitl null and dragKey-parentId = foundId
         */

        const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === key) {
            return callback(data[i], i, data);
            }
            if (data[i].children) {
            loop(data[i].children, key, callback);
            }
        }
        };        
        const data = [...treeData];                

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
        });

        if (!info.dropToGap) {

        // Drop on the content
        loop(data, dropKey, item => {
            item.children = item.children || [];
            // where to insert the dragged object
            item.children.push(dragObj);
        });

        } else if (            
        (info.node.props.children || []).length > 0 && // Has children
        info.node.props.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert dragged object
                item.children.unshift(dragObj);
            });
            } else {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
        if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
        } else {
            ar.splice(i + 1, 0, dragObj);
        }
        }

        setTreeData(data);
    }

    /**
     * Used to update the order of the tree categories
     * by drag and drop
     */
    const updateOrder = (draggedCategory, droppedCategory) => {
        let data = {
            parent_id: droppedCategory
        };
        setOrderStatus('Saving Order...');
        apiInstance.put(`/category/order/${draggedCategory}`, data)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);                                   
            } else message.warning(response.data?.data || 'Something went wrong');            
            getAllCategories();            
        })
        .catch(e => {            
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
            getAllCategories();                
          });
    }

    /**
     * Used to get the current selected id 
     * of a category and into the state
     * @param {id} id 
     */
    const onSelect = (id)  => {        
        // setSelectedCategory(id[0]);
        // UPDATE THE SELECTED CATEGORY
        dispatch({
            type: 'UPDATE_SELECTED_CATEGORY',
            item: id[0]
        })        
    };

    /**
     * Used to create a new category or a sub category to any 
     * exisiting category
     * @param {title,description,image,parentId} data 
     */
    const createACategory = (flag, data) => {
        setLoad(true);
        apiInstance.post("/category", data)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);   
                dispatch({
                    type: 'SET_CATEGORIES_STATUS',
                    status: !categoriesStatus
                });
            } else message.warning(response.data?.data || 'Something went wrong');
            setLoad(false);
            setAddModalTrigger(flag);
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
          });
    }

    /**
     * Used to update a category or a sub category that exists
     * @param {boolean} flag 
     * @param {*} data 
     */
    const updateACategory = (flag, data) => {
        setLoad(true);
        apiInstance.put(`/category/${data.id}`, data)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);   
                dispatch({
                    type: 'SET_CATEGORIES_STATUS',
                    status: !categoriesStatus
                });
            } else message.warning(response.data?.data || 'Something went wrong');
            setLoad(false);
            setUpdateModalTrigger(flag);
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    /**
     * Used to soft delete a category that exists
     */
    const deleteACategory = () => {
        setLoad(true);        
        apiInstance.put(`/category/status/${selectedCategory}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);   
                dispatch({
                    type: 'SET_CATEGORIES_STATUS',
                    status: !categoriesStatus
                });
            } else message.warning(response.data?.data || 'Something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    /**
     * Used to update the status of a particular 
     * category that exists
     */
    const changeStatus = (status) => {
        setLoad(true);        
        let data = {
            status
        };
        apiInstance.put(`/category/status/${selectedCategory}`, data)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);   
                dispatch({
                    type: 'SET_CATEGORIES_STATUS',
                    status: !categoriesStatus
                });
            } else message.warning(response.data?.data || 'Something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });
    }

    const actionFromModal = (flag=false, type, payloadData) => {
        switch(type) {
            case 'create': createACategory(flag, payloadData);                    
                            break;
            case 'update': updateACategory(flag, payloadData);
                            break;
            default: setAddModalTrigger(false);
                     setUpdateModalTrigger(false);
        }
    }

    return (
        <div className="w-full h-full pr-8">

            <div className="flex items-center">
                <Button
                    onClick={() => setAddModalTrigger(true)}
                    className="flex items-center"
                    type="primary"                    
                ><PlusOutlined /> Add                  
                </Button>
                <Button
                    disabled={!selectedCategory}
                    onClick={() => setUpdateModalTrigger(true)}
                    className="mx-4"
                    type="default"                
                >Update             
                </Button>   
                <Popconfirm 
                        disabled={!selectedCategory}                                            
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                        onConfirm={deleteACategory}
                >
                {/* <Button danger                    
                    disabled={!selectedCategory}
                    type="default"                     
                >Delete                    
                </Button> */}
                </Popconfirm>     
                <Switch
                    loading={load}
                    checked={categories.filter(c => c.id === selectedCategory)[0]?.is_active}
                    disabled={!selectedCategory}
                    size="small"                                       
                    className="mx-2"                    
                    onChange={(e) => changeStatus(e)}
                />                   
            </div>
            {
                <p className="mt-2 text-xs text-gray-600"
                >{orderStatus}                    
                </p>
            }
            {
                categories.length ?
                    <Tree  
                        defaultExpandAll                         
                        selectedKeys={[selectedCategory]}
                        className="py-6 draggable-tree"              
                        draggable
                        blockNode
                        onSelect={onSelect}
                        onDrop={onDrop}
                        treeData={treeData}
                        height={400}
                    />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }

            {
                addModalTrigger ?
                <CategoryModal 
                    type="create"
                    modalTitle="Add Category"
                    okLabel="Add"
                    trigger={addModalTrigger}
                    callTrigger={actionFromModal}
                    progress={load}
                /> : null
            }

            {
                updateModalTrigger ?
                <CategoryModal                    
                    type="update"
                    modalTitle="Update Category"
                    okLabel="Save"
                    trigger={updateModalTrigger}
                    callTrigger={actionFromModal}
                    progress={load}
                /> : null
            }

        </div>
    )
}

export default Category
