import React from 'react'
import { Link } from 'react-router-dom'

function SubMenus({ title, data=[], type=null }) {
    
    return (
        <div className="w-3/4 flex flex-col items-center">
            <h5 className="text-lg text-gray-800 text-left w-full font-bold mb-3"
            >{title}              
            </h5>
            <div className="w-full grid grid-cols-3 gap-5 my-2">                
                    {
                        type === 'search' ?
                        data.map(({ category_id, title }) => (
                            <Link
                                to={`/article/${title}`} 
                                className="border-b-2 border-gray-400 px-2 cursor-pointer hover:border-orange-500">
                                <p className="p-1 text-base text-gray-800"
                                >{title}                            
                                </p>
                            </Link>                            
                        ))
                        : 
                        data.map(({ id, title }) => (
                            <Link
                                to={`/article/${title}`} 
                                className="border-b-2 border-gray-400 px-2 cursor-pointer hover:border-orange-500">
                                <p className="p-1 text-base text-gray-800"
                                >{title}                            
                                </p>
                            </Link>                            
                        ))                        
                    }                    
            </div>                 
        </div>
    )
}

export default SubMenus
