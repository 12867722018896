import React, { useEffect, useState } from "react"
import moment from 'moment'
import '../../stylesheets/FeedBox.css'

function FeedBox({ data, category=false, time=false, author=false, comments=false, image=true, enclosure=true, mainImage=null }) {        
    
    const [postDescription, setPostDescription] = useState(null);
    const [postImage, setPostImage] = useState(null);
    const [postTitle, setPostTitle] = useState(null);

    // useEffect(() => {       
    //     var images = document.getElementsByTagName('img');                                        
    //     for (var i=0; i<images.length; i++) {                         
    //         if(!image) images[i].style.display = 'none';                                                 
    //         if(imgHeight) images[i].style.height = imgHeight;
    //         if(imgWidth) images[i].style.width = imgWidth;
    //         if(image) images[i].style.marginBottom = '15px';            
    //      }                
    //      // eslint-disable-next-line       
    // },[data]);  
    
    useEffect(() => {  

        let title = data?.title;        
        if(typeof title === 'object') {
            if (title.hasOwnProperty('a')) {
                if (title['a']?.length) {
                    if (title['a'][0]?.hasOwnProperty('_')) {
                        setPostTitle(title['a'][0]['_']);
                    } 
                } 
            } 
        } else setPostTitle(title);

        let description = data?.description || data?.content || data?.contentSnippet;
        let doc = new DOMParser().parseFromString(description, "text/html");
        setPostImage(doc.getElementsByTagName('img')[0]?.src||null);

        if(description) {
            let imgRemovedDescription = description.replace(/<img[^>]*>/g,"");            
            setPostDescription(imgRemovedDescription);
        }

    },[data]);
   
  return (
    <div 
        id="content__container"
        className="w-full border rounded-sm shadow p-5 flex flex-col lg:mr-10 my-4">
            <div id="homeBox" className="w-full flex flex-col lg:flex-row items-start justify-between">
                <div className={`${data?.enclosure?.type?.split('/')[0]!=='image'?'w-3/4':'w-full'} flex items-start flex-col`}>                    
                    <a 
                        rel="noopener noreferrer"
                        target="_blank"
                        href={data?.url || data.link}
                        className="feedBox__link font-bold text-base lg:text-xl primary-color cursor-pointer hover:text-underline">
                        {postTitle}
                    </a>
                    <p 
                        className="homeBox__description w-full text-gray-600 lg:block hidden text-md py-1"
                        dangerouslySetInnerHTML={{ __html: postDescription }}
                    ></p> 
                    <div className="flex items-center">        
                        {
                            (author && (data?.author || data?.creator)) &&
                            (
                                <div
                                    className="py-1 flex items-center"
                                ><span role="img" aria-label="emoji">&#9997;</span>
                                    <p  
                                        title={data?.author || data.creator}
                                        className="mx-2 text-xs text-gray-600">                        
                                        {data?.author || data.creator}
                                    </p>
                                </div>
                            )
                        }
                        {
                            time && (moment(data.pubDate).isValid()) &&
                            (
                                <div
                                    className="py-2 flex items-center"
                                ><span role="img" aria-label="emoji">&#128338;</span>
                                    <p  
                                        title={data.pubDate}
                                        className="mx-2 text-xs text-gray-600">                        
                                        {moment(data.pubDate).isValid() ? new Date(data.pubDate).toDateString()===new Date().toDateString()?moment(data.pubDate).fromNow():moment(data.pubDate).format('ll') : ''}                                        
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    !!postImage && image && (
                        <div className="homeBox__hoverImage lg:h-32">
                            <img
                                className="homeBox__image lg:h-40"
                                src={postImage}
                                alt=""
                            />
                        </div>
                    )
                }
                {
                data.enclosure ?
                    enclosure && 
                    (
                        data.enclosure.type === 'image/jpeg' || data.enclosure.type === 'image/png' || data.enclosure.type === 'image/jpg' ?
                        (                            
                                <img className="w-1/3 mx-3" 
                                    src={data.enclosure.url}
                                    alt="feedboximage"
                                />                                                                        
                        )
                        : (
                            <div className="flex items-start mx-2">
                                <video controls poster={data?.itunes?.image||mainImage||''} className="mr-3" preload="none">
                                    <source src={data.enclosure.url} type={data.enclosure.type}></source>
                                </video>                                           
                            </div>
                        )
                    )
                : null
                
                }
            </div>                    
        
        {
            (comments && data?.comments) && 
            (                            
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={data.comments}
                        className="underline text-xs p-1 w-32 rounded-sm cursor-pointer primary-color"
                    >View Comments</a>                
            )
        }
        {
            category &&
            (
                data?.categories?.length && (                    
                        <div>
                            <div className="lg:flex hidden items-center my-2">
                            {                                                            
                                    data.categories.slice(0,8).map((item, idx) => (
                                        typeof item === 'object' ? 
                                        (
                                            item.hasOwnProperty('_') && (
                                                item['_'].indexOf('/') !== -1 ?
                                                (
                                                    item['_'].split('/').map(i => (
                                                        <p
                                                            key={idx}
                                                            className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                                        >{i}</p>
                                                    ))
                                                )
                                                : (
                                                    <p
                                                        key={idx}
                                                        className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                                    >{data.categories['_']}</p>    
                                                )
                                            )                                    
                                        ) : (
                                            <p
                                                key={idx}
                                                className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                            >{item}</p>
                                        )                                        
                                    ))                             
                            }                            
                                </div> 
                                <div className="grid grid-cols-2 gap-2 lg:hidden items-center my-2">
                                {                                                            
                                        data.categories.slice(0,8).map((item, idx) => (
                                            typeof item === 'object' ? 
                                            (
                                                item.hasOwnProperty('_') && (
                                                    item['_'].indexOf('/') !== -1 ?
                                                    (
                                                        item['_'].split('/').map(i => (
                                                            <p
                                                                key={idx}
                                                                className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                                            >{i}</p>
                                                        ))
                                                    )
                                                    : (
                                                        <p
                                                            key={idx}   
                                                            className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                                        >{data.categories['_']}</p>    
                                                    )
                                                )                                    
                                            ) : (
                                                <p
                                                    key={idx}
                                                    className="feedBox__categoryLabel bg-blue-500 border border-blue-700 text-white text-xs p-1 rounded-md mr-2"
                                                >{item}</p>
                                            )                                        
                                        ))                             
                                }                            
                            </div>
                        </div>                    
                )
            )
        }        
    </div>
  );
}

export default FeedBox;
