import React,{ useEffect, useState } from 'react'
import logo from '../assets/img/logo.png'
import { Input, message } from 'antd'
import '../stylesheets/Login.css'
import { Link } from 'react-router-dom'
import apiInstance from '../api'
import { validateEmail } from '../utils/Helper'

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Log In');    
    
    useEffect(() => {
        document.title = "Log in to Assistance | Assistance";                  
    }, []);

    const logIn = (e) => {
        e.preventDefault();
        if(username && password) {            
        
            if(username.indexOf('@')>=0) {
                if(validateEmail(username)) {
                    signInWithEmailOrUsername(username, password);
                } else message.warning('Invalid Email Address');
            } else  signInWithEmailOrUsername(username, password);
            
        } else message.warning('User name or Password missing');
    }

    const signInWithEmailOrUsername = (username, password) => {
            let userLoginInfo = { // PAYLOAD FOR LOGGING IN AS USER
                username,
                password
            };

            // START REQUESTING API
            setLoad(true);
            setButtonLabel('Logging In');

            apiInstance.post("/auth/login", userLoginInfo)
            .then(response => {   
                
                if(response.data.status) {
                    // CLEAR TO GO !!!
                    const _token = response.data.token; // TOKEN FROM AUTHENTICATION API
                    const _user = response.data.payload.user; // USER DATA FROM API
                    const _permissions = response.data.payload.permissions; // PERMISSION DATA FROM API

                    sessionStorage.setItem('assistance_token', _token);
                    sessionStorage.setItem('assistance_user', JSON.stringify(_user));
                    sessionStorage.setItem('assistance_permissions', _permissions);

                    setUsername('');
                    setPassword('');                                
                                
                    window.location.replace("/dashboard");
                }                                                           
                setLoad(false);
                setButtonLabel('Log In');                 
            }).catch(e => {                
                console.log(e.response);
                if(e.response || e.response?.data) {                                    
                    if(e.response.status === 404 || e.response.status === 300) {
                        message.warning(e.response.statusText);
                    } else message.error(e.response.data?.message|| 'Something went wrong');
                } else message.error("Internal Server Error");
                setLoad(false);
                setButtonLabel('Log In');
                setPassword('');                                
            });
    }

    return (
        <div    
            className="w-full max-h-full lg:h-screen flex flex-col items-center py-16 bg-white lg:px-12"
        >
            <Link
                to="/"
            >
                <img
                    alt="logo"
                    src={logo}
                    className="h-12 object-contain mb-10"
                />
            </Link>
            <div 
                className="lg:w-1/3 md:w-1/2 bg-white border rounded-lg px-3 py-4
                            flex flex-col items-center shadow-md"
            >
                <h1 className="text-lg text-black py-3">
                    Log in to Assistance
                </h1>
                <form className="login">
                    <Input 
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text" 
                        placeholder="Username or Email address"
                        className="p-3 my-2 rounded-lg text-black"
                    />
                    <Input.Password 
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        className="p-3 my-2 rounded-lg text-black"
                    />
                    <button                            
                        disabled={load}                                      
                        type="submit"              
                        onClick={logIn}
                        className="login__button w-full text-white font-bold rounded-lg text-lg block primary-bg text-center
                                   p-3 my-2 cursor-pointer mb-4 focus:outline-none"
                    >{buttonLabel}                   
                    </button>
                </form>
                <Link
                    to="/"
                    className="w-full text-center py-1 text-md
                                hover:underline primary-color"
                >Back to Home</Link>
            </div>
        </div>
    )
}

export default Login
