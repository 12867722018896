import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import LinkTool from '@editorjs/link'
import Quote from '@editorjs/quote'
import Delimiter from '@editorjs/delimiter'
import Header from '@editorjs/header'

export const EDITOR_JS_TOOLS = {
    header: {
        class: Header,
        inlineToolbar: ['link']
    },
    paragraph: {
        class: Paragraph,
        inlineToolbar: true
    },
    list: {
        class: List,
        inlineToolbar: true
    },
    quote: Quote,
    delimiter: Delimiter,
    linkTool: {
        class: LinkTool,
        config: {
            endpoint: 'http://localhost:4000/api/feed/fetchUrl',
        }
    }
}