import React from 'react'
import Header from '../components/User/Header'
import Footer from '../components/User/Footer'
import { Link } from 'react-router-dom'
import masterImage from '../assets/img/general-assistance1.png'

function About() {    

    document.title = 'About - Assistance';    

    return (
        <>
            <Header />    
            <div id="about" className="px-4 lg:px-24 w-full h-full py-8 flex flex-col items-center lg:items-start">
                <div className="w-full">
                    <Link 
                        to="/"
                        className="flex items-center">
                        {/* <img                
                            alt="logo"    
                            className="h-32 object-contain ml-12 my-2"
                            src={logo}
                        />                    
                         */}
                         <img                
                            alt="logo"    
                            className="object-contain lg:ml-12 my-2"
                            style={{ maxHeight: 400 }}
                            src={masterImage}
                        /> 
                    </Link>
                    <h3 className="text-left font-extrabold text-2xl lg:text-5xl lg:px-12">Welcome to <span className="primary-color">assistance.org</span></h3>                
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                        We are so happy you are here!
                    </p>
                    <p className="text-gray-700 py-1 text-justify lg:px-12 text-lg">
                        Everyone needs a helping hand once in a while. Assistance.org connects people who are in need of assistance for all walks of life. Join today to be part of this network of helping hands!
                    </p>
                </div>
                <div id={'mission'} 
                    className="w-full py-8"                    
                >                                                              
                    <h3 className="text-left font-extrabold text-3xl py-3 lg:px-12">Mission</h3>                
                    <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                        Provide a safe and secure platform for local communities where people connect to help each other by sharing information or providing assistance.
                    </p>                    
                </div>
                <div className="lg:px-12">
                    <Footer type="full" />
                </div>
            </div>
        </>
    )
}

export default About
