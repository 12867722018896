
import axios from 'axios'
import dotenv from 'dotenv'
dotenv.config();

const apiInstance = axios.create({
    baseURL: `${window.location.protocol}//${process.env.REACT_APP_BASE_URL}`,
    headers: { 'authorization': `Bearer ${sessionStorage.getItem('assistance_token')}` || '' }
});

export default apiInstance;