import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Index from './pages/Index'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Contact from './pages/Contact'
import About from './pages/About'
import Category from './pages/Category'
import Search from './pages/Search'
import NotFound from './pages/NotFound'
import Feed from './pages/Feed'
import apiInstance from "./api"
import { useDataLayerValue } from './DataLayer'
import { addKeyToData } from './utils/Helper'
import DevBanner from './components/DevBanner'        
import FallBack from './pages/FallBack'
import OurMission from './pages/OurMission'
import Terms from './pages/Terms'

function App() {
  
  const [{ categoriesStatus, feedsStatus }, dispatch] = useDataLayerValue();  
    
  useEffect(() => {    
    async function getAllCategories() {
        apiInstance.get("/category")
        .then(response => {
          if(response.data.status) {
            // SHOOT DATA TO THE DATA LAYER         
            dispatch({
              type: 'SET_CATEGORIES',
              categories: response.data.data
            });
          } else {
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {
          console.log(e);
          if(e.response || e.response?.data) {                
              // message.error(e.response.data.message);
              // window.location.replace('/fallback');
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else {
            // window.location.replace('/fallback');
          }
        });
    }
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoriesStatus]);

  useEffect(() => {
    async function getAllFeeds() {
      apiInstance.get("/feed")
      .then(response => {
        if(response.data.status) {
          // SHOOT DATA TO THE DATA LAYER
          addKeyToData(response.data.data, "id");
          dispatch({
            type: 'SET_FEEDS',
            feeds: response.data.data
          });
        } else {
          // window.location.replace('/fallback');
          console.log(response.data?.data||response?.status);            
        }
      })
      .catch(e => {
        console.log(e);
        if(e.response || e.response?.data) {                          
            // message.error(e.response.data.message);
            if(e.response.status === 401) {
                sessionStorage.clear();
                window.location.replace("/admin");
            }
        } else {
          // window.location.replace('/fallback');
        }
      });  
    }
    getAllFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[feedsStatus]);

  return (
    <div>
        {
          process.env.REACT_APP_ENV === 'staging' ?
          <DevBanner title={"DEVELOPMENT MODE"} /> : null
        }
        <Router>                   
          <Switch>

            <Route path="/dashboard"> <Dashboard /> </Route>
            <Route path="/admin"> <Login /> </Route>
            <Route path="/contact"> <Contact />  </Route>
            <Route path="/about"> <About />  </Route>
            <Route path="/mission"> <OurMission /> </Route>
            <Route path="/terms"> <Terms /> </Route>
            <Route path="/article/:id"> <Category />  </Route>            
            {/* <Route path="/feed/:id"> <Feed />  </Route> */}
            <Route path="/search"> <Search />  </Route>
            <Route path="/fallback"> <FallBack /> </Route>
            <Route path="/:feedkey" exact> <Feed /> </Route>
            <Route path="/" exact> <Index /> </Route>
            <Route path="*"> <NotFound />  </Route>            

          </Switch>                          
        </Router>
    </div>  
  )

}

export default App;

