import React, { useState } from 'react'
import Header from '../components/User/Header'
import Footer from '../components/User/Footer'
import { Input, message, Result } from 'antd'
import { SmileOutlined } from '@ant-design/icons';
import '../stylesheets/Contact.css'
import { validateEmail } from '../utils/Helper'
import { Link, useHistory } from 'react-router-dom'
import apiInstance from '../api/'

const { TextArea } = Input;

function Contact() {
    let history = useHistory();

    document.title = 'Contact Us - Assistance';

    const [buttonLabel, setButtonLabel] = useState('Submit');
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [done, setDone] = useState(false);

    const submitMessage = (e) => {                
        e.preventDefault();        
        setButtonLabel('Submitting');        
        if(!validateEmail(email)) message.warning('Email is not valid');
        if(fullname && email && contactMessage && validateEmail(email) && subject) {
             // your logic here
            let payload = {
                fullname,
                email,
                contactMessage,
                subject
            };
            apiInstance.post("/homepage/contact", payload)
            .then(response => {                
                message.success('Submitted Successfully');
                setFullname('');
                setEmail('');
                setSubject('');
                setContactMessage('');
                setButtonLabel('Submit');
                setDone(true);
            }).catch(e => {                
                console.log(e);                
                // message.error("Internal Server Error");
                setFullname('');
                setSubject('');
                setEmail('');
                setContactMessage('');
                setButtonLabel('Submit');
                history.push('/fallback')
            });            
        } else {            
            setButtonLabel('Submit');
            message.info('Input is badly formatted');            
        }
    }

    return (
        <>
            <Header />
            <div className="w-full flex flex-row items-center justify-center">
               {
                !done ?             
                ( <form className="w-full px-10 lg:px-24 py-12 contact lg:w-1/2">                    
                    <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                        <p>Full Name</p>
                        <Input 
                            value={fullname}
                            onChange={e => setFullname(e.target.value)}
                            required                                                
                            type="text" 
                            placeholder="Full Name"
                            className="p-3 my-2 rounded-lg text-black"
                        />
                    </div>
                    <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                        <p>Email Address</p>
                        <Input 
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required                                                
                            type="email" 
                            placeholder="Email Address"
                            className="p-3 my-2 rounded-lg text-black"
                        />
                    </div>
                    <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                        <p>Email Subject</p>
                        <Input 
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                            required                                                
                            type="text" 
                            placeholder="Email Subject"
                            className="p-3 my-2 rounded-lg text-black"
                        />
                    </div>                    
                    <div className="contact__inputGroup flex flex-col items-start py-2 w-full">
                        <p>Your Message</p>
                        <TextArea                             
                            value={contactMessage}
                            onChange={e => setContactMessage(e.target.value)}
                            required
                            placeholder="Enter Message" 
                            rows={4} 
                            className="p-3 my-2 rounded-lg text-black" 
                        />
                    </div> 
                    <div className="flex flex-row items-center">
                        <button  
                            disabled={buttonLabel==='Submitting'}
                            onClick={submitMessage}                         
                            type="submit"             
                            className={`primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2 ${buttonLabel==='Submitting'?'cursor-not-allowed':''}`}
                        >
                        {buttonLabel}
                        </button>                   
                        <Link  
                            to="/"
                            className="bg-white rounded-md primary-color hover:bg-gray-100 border px-8 mx-2 py-2 hover:text-gray-800 my-2"
                        >
                        Cancel
                        </Link>                   
                    </div>
                </form> ) : (
                    <Result 
                        icon={<SmileOutlined />}
                        title="Thank you for contacting us!"
                        subTitle="We will get back to you soon."
                        extra={[
                            <Link to="/" key="console" className="primary-bg rounded-md text-white px-8 mx-2 py-2 hover:text-gray-400 my-2">
                                Back to Home
                            </Link>,
                          ]}
                    />
                )}

                <Result
                    className="hidden lg:block"
                    status="404"
                    title="Contact Us"
                    subTitle="Do not hesitate to contact us for any queries you have"
                    extra={null}
                />
            </div>
            <div className="lg:px-12 px-8 py-10">
                <Footer type="full" />
            </div>
        </>
    )
}

export default Contact
