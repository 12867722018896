import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function Permissions() {

    const [{ allPermissions, allPermissionsStatus }, dispatch] = useDataLayerValue();  

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    
    
    /**
     * Used to create a permission using the 
     * standard API
     * @param {*} data 
     */
    const createAPermission = (data) => {
        setLoad(true);
        apiInstance.post("/permission", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW PERMISSIONS ADDED
                dispatch({
                    type: 'SET_ALL_PERMISSIONS_STATUS',
                    status: !allPermissionsStatus
                });
            } else message.warning(response.data.data);  
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
            return false;
        });        
    }

    /**
     * Used to update a permission using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
    const updateAPermission = (id, data) => {
        setLoad(true);
        apiInstance.put(`/permission/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW Permission ADDED
                dispatch({
                    type: 'SET_ALL_PERMISSIONS_STATUS',
                    status: !allPermissionsStatus                    
                });
            } else message.warning(response.data.data); 
            setLoad(false);           
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });        
    }

    /**
     * Used to soft delete a permission using the
     * standard API
     * @param {*} id 
     */
    const deleteAPermission = (id) => {
        setLoad(true);
        apiInstance.put(`/permission/delete/${id}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);
                setLoad(false);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW PERMISSIONS ADDED
                dispatch({
                    type: 'SET_ALL_PERMISSIONS_STATUS',
                    status: !allPermissionsStatus                    
                });            
                return true;    
            } else message.warning(response.data.data);
            setLoad(false);
        })
        .catch(e => {
            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });        
    }

    /**
     * Used to change the current status of
     * a permission based on provided id
     * @param {*} id 
     */
    const changeStatusOfAPermission = (data, id) => {
        setLoad(true);
        apiInstance.put(`/permission/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW PERMISSIONS ADDED
                dispatch({
                    type: 'SET_ALL_PERMISSIONS_STATUS',
                    status: !allPermissionsStatus                    
                });
            } else message.warning(response.data.data);  
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });        
    }

    return (
        <div className="w-full">
            <CrudUI
                title="Permission"
                utility="Permissions"
                data={allPermissions}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createAPermission}
                updateItem={updateAPermission}
                deleteItem={deleteAPermission}
                changeStatus={changeStatusOfAPermission}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </div>
    )
}

export default Permissions
