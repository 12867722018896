import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/User/Header'
import { Result } from 'antd'

function FallBack() {
    return (
        <>
            <Header />
            <Result
                status="500"
                title="OOPS!"
                subTitle="Sorry, Something went wrong."
                extra={
                    <Link
                        to="/"
                        className="primary-bg rounded-md text-white px-4 py-2 hover:text-gray-400"
                    >
                        Back Home
                    </Link>
                }
            />
        </>
    )
}

export default FallBack
