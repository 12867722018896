import React, { useEffect, useState } from 'react'
import Header from '../components/User/Header'
import { useParams, useHistory } from 'react-router-dom'
import { message, Typography, Skeleton } from 'antd'
import { SearchOutlined, FacebookFilled, WhatsAppOutlined, TwitterOutlined } from '@ant-design/icons'
import { useDataLayerValue } from '../DataLayer'
import apiInstance from '../api'
import FeedBox from '../components/User/FeedBox'
import { validateURL } from '../utils/Helper'
import CategoryHeader from '../components/User/CategoryHeader'
import CategorySlide from '../components/User/CategorySlide'
import SearchBar from '../components/User/SearchBar'
import Footer from '../components/User/Footer'
import PreLoaderList from '../components/User/PreLoaderList'
import TimeMe from 'timeme.js'
import assistanceIcon from '../assets/img/icon.png'
import EditorJSRenderer from '../components/User/EditorJSRenderer'
import moment from 'moment'

const { Paragraph } = Typography;

function Feed() {
    let { feedkey } = useParams();
    let history = useHistory();

    // const loadIcon = <LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />;

    const [{ feeds, categories }] = useDataLayerValue();

    const [selectedFeed, setSelectedFeed] = useState(null);    
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [rssFeed, setRssFeed] = useState(null);    
    const [mainImage, setMainImage] = useState(null);    
    const [searchText, setSearchText] = useState('');
    const [filtered, setFiltered] = useState([]);    

    useEffect(() => {        
        const newSelectedFeed = feeds.filter(f => f.url_key === feedkey)[0];
        const newSelectedCategory = categories.filter(c => c.id === newSelectedFeed?.category_id)[0];
        setSelectedCategory(newSelectedCategory);
        if(newSelectedFeed) {
            if(parseInt(newSelectedFeed?.is_active||0) === 1) {
                setSelectedFeed(newSelectedFeed); 
                document.title = `${newSelectedFeed?.title} - Assistance`;
            } else {
                history.push('/');
                message.warning('Feed is not available right now!');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[feeds, feedkey, categories]);

    useEffect(() => {        
        TimeMe.initialize({
            currentPageName: 'feed-page'            
        });
        if(selectedFeed && selectedFeed?.feed_type === 'RSS Feed') {            
            getRSSFeed(selectedFeed.url);   
            isImageOk(selectedFeed.image);                                           
        } else if(selectedFeed?.feed_type === 'External Blog') {
            window.location.replace(selectedFeed?.url||'/');
        }        
// eslint-disable-next-line react-hooks/exhaustive-deps 
    },[selectedFeed]);

    async function getRSSFeed(url) {                
        apiInstance.get(`/homepage/rss?url=${url}`)
        .then(response => {
            setRssFeed(response.data);                    
        }).catch(e => {
            console.log(e);
            history.push('/');
            // if(e.response && e.response?.data) {
            //     message.error(e.response.data)
            // } else message.error('Internal Server Error');
        })

        // let parser = new RSSParser();
        // // REMOVE AFTER DEVELOPMENT
        // const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"            
        // parser.parseURL(CORS_PROXY + url, (err, feed) => {
        //     if(err) message.error(err);
        //     setRssFeed(feed);
        //     setLoad(false);
        // });        
    }

    async function isImageOk(imageUrl) {
        if(validateURL(imageUrl) && imageUrl.indexOf('h') === 0) {            
            let img = new Image();
            img.src = imageUrl;
            img.onload = function() { setMainImage(imageUrl) }
            img.onerror = function() { setMainImage(null) }
        } else {
            setMainImage(null);
        }
    }

    useEffect(() => {
        var links = document.getElementsByTagName('a');
        for (var i=0; i<links.length; i++) {
            links[i].target = '_blank';            
        }
    },[rssFeed, selectedFeed]);
    
    const filterFeed = (text) => {
        setSearchText(text);
        if(text) {            
            let filteredData = rssFeed.items.filter(feed => {
                return (
                    feed.title.toLowerCase().includes(text.toLowerCase()) ||
                    feed?.content.toLowerCase().includes(text.toLowerCase()) ||
                    feed?.categories?.map(i => i.toLowerCase())?.includes(text.toLowerCase()) ||
                    feed?.categories['_']?.includes(text.toLowerCase()) ||
                    feed?.categories?.map(i => i?.toLowerCase()).some(item => item.includes(text.toLowerCase()) && text !== '')
                )
            });
            setFiltered(filteredData);            
        } else {
            setFiltered([]);
        }
    }

    const logCategory = () => {
        if(TimeMe.getTimeOnCurrentPageInSeconds() > 60) {            
            let category = {
                id: selectedFeed?.category_id || null
            };
            let categoryLog = JSON.parse(localStorage.getItem('assistance-category-log'));
            if(categoryLog?.length) {
                if(!categoryLog.filter(c => c.id === selectedCategory?.id).length) {
                    localStorage.setItem('assistance-category-log', JSON.stringify([...categoryLog, category]));
                }
            } else localStorage.setItem('assistance-category-log', JSON.stringify([category]));
            TimeMe.resetRecordedPageTime('feed-page');  
        }
    }
    
    useEffect(() => {
        document.onmousemove = logCategory; 
        document.onkeypress = logCategory;
        document.onblur = logCategory; 
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    },[selectedFeed]);
    
    return (
        <div>            
            <Header />
            <CategoryHeader
                id={selectedCategory?.id}
                title={selectedCategory?.title}
                image={selectedCategory?.image||null}
            />
            <div className="px-6 lg:px-24 w-full flex flex-col items-start">
        
                <div className="flex items-center w-full py-4">
                    {
                        selectedFeed?.feed_type === 'Internal Blog' ? (
                            <div className="w-full flex flex-col items-start">
                                <h1 className="font-bold text-xl lg:text-3xl text-left py-1">
                                    {selectedFeed?.title}
                                </h1>
                                <div className="flex lg:flex-row flex-col items-start lg:items-center">
                                            <p className="text-gray-500 lg:text-md text-xs text-left">
                                                {moment(selectedFeed?.created_at).format('lll')}
                                            </p>
                                            <div className="mr-3 lg:mx-3 py-1 mb-2 flex items-center">
                                                <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.href}&quote=${selectedFeed?.url_key}`} className="text-lg mr-2 lg:mx-2"><FacebookFilled /></a>
                                                <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?text="+selectedFeed?.url_key+"&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                                <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text="+selectedFeed?.url_key+" Visit: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                            </div> 
                                </div>                                
                            </div>
                        ) : (

                            !!selectedFeed ? (
                                <div className="lg:w-1/2 flex lg:flex-row flex-col items-start">                                               
                                        <img 
                                            alt="feed"
                                            className="object-contain w-16 h-16 mx-4"                                
                                            src={mainImage||assistanceIcon}
                                        />                        
                                    <div className={mainImage ? 'flex flex-col items-start mx-4' : 'flex flex-col items-start'}>
                                        <a 
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={rssFeed?.link}
                                            className="text-xl text-left font-bold primary-color"
                                        >{selectedFeed?.title||'N/A'}                             
                                        </a> 
                                        {
                                            !!selectedFeed?.description && (
                                                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} 
                                                    className="text-md text-gray-600 "
                                                >{selectedFeed?.description||'N/A'}
                                                </Paragraph>
                                            )
                                        }
                                    </div>                        
                                </div> 
                            ): (
                                <>
                                    <Skeleton loading={true} active className="border-b mb-4">
                                        <h4>Assistace.org Sample Title</h4>                                    
                                    </Skeleton>                            
                                </>
                            )                                                                             
                        )
                    }                                       
                </div>

            <div className="w-full flex lg:flex-row flex-col items-start">

                <div className="lg:w-3/4 flex flex-col items-start">
                    {
                        selectedFeed?.feed_type !== 'Internal Blog' && (
                            <div 
                                className="lg:w-1/2 flex items-center justify-between mb-3 rounded-lg shadow p-1 border">           
                                <input
                                    placeholder="Search lastest posts..."
                                    className="w-full p-1 rounded-full bg-none primarycolor text-gray-700 mx-3 focus:outline-none"
                                    onChange={e => filterFeed(e.target.value)}
                                    value={searchText}
                                    type="text"
                                />
                                <SearchOutlined                     
                                    className="p-2 mr-2 text-lg cursor-pointer" 
                                />
                            </div>   
                        )
                    }                    
                        {
                            selectedFeed ?

                            selectedFeed?.feed_type === 'Internal Blog' ? (

                                <EditorJSRenderer
                                    data={JSON.parse(selectedFeed.content).blocks}  
                                    image={selectedFeed.image}                                  
                                />

                            ) : 
                                rssFeed ?
                                ((searchText && !filtered.length) ? filtered : rssFeed.items)?.length ?
                                (
                                    ((filtered && filtered.length) ? filtered : rssFeed.items).map((item, i) => (
                                        <FeedBox
                                            mainImage={selectedFeed?.image}
                                            key={i}
                                            data={item}                                                                                
                                            time 
                                            author
                                            category                                        
                                        />                                    
                                    ))
                                )
                                    : <p
                                        className="tex-gray-500 text-sm text-left"
                                    >No Posts Found
                                    </p> 
                                : 
                                <PreLoaderList />                                                       
                            : null
                        }                                              
                </div>
                <div className="lg:ml-4 lg:w-1/3 w-full mb-4 sticky top-0">
                    <SearchBar 
                        placeholderText="What you need assistance for ?"
                        data={feeds}
                    />  
                    <CategorySlide />
                    <CategorySlide
                        boxTitle="Similar Articles"
                    />
                    <Footer />
                </div>                
            </div>

            </div>         
        </div>
    )
}

export default Feed
