import React, { useEffect, useState } from 'react'
import Header from '../components/User/Header'
import { useParams, useHistory } from 'react-router-dom'
import { useDataLayerValue } from '../DataLayer'
import ListMenu from '../components/User/ListMenu'
import CategoryHeader from '../components/User/CategoryHeader'
import CategorySlide from '../components/User/CategorySlide'
import SearchBar from '../components/User/SearchBar'
import Footer from '../components/User/Footer'

function Category() {
    let { id } = useParams();    
    let history = useHistory();

    const [{ categories, feeds }] = useDataLayerValue();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [feedsFromCategory, setFeedsFromCategory] = useState([]);

    useEffect(() => {

        let filterData = categories.filter(c => c.title === id);
        
        const newSelectedCategory = filterData.length ? filterData[0] : null;
        setSelectedCategory(newSelectedCategory); 
        
        document.title = `${newSelectedCategory?.title} - Assistance`;

        const newSubCategories = categories.filter(c => c.parent_id === parseInt(id));
        setSubCategories(newSubCategories);        

        const newFeedsFromCategory = feeds.filter(f => f.category_id === categories.filter(item => item.title === id)[0]?.id);
        setFeedsFromCategory(newFeedsFromCategory);

        if (newSelectedCategory && !newFeedsFromCategory.length && newSubCategories.length) {
            history.push(`/article/${newSubCategories[0]?.title}`);
        }
        
  // eslint-disable-next-line react-hooks/exhaustive-deps
    },[categories, feeds, id]);

    return (
        <div className="w-full h-full bg-white">            
            <Header />
            <CategoryHeader 
                id={categories.filter(item => item.title === id)[0]?.id}
                title={id}
                image={selectedCategory?.image||null}  
            />  
            <div className="w-full flex items-start justify-between lg:px-16">
                <div className="w-full px-4 mt-3 mb-8 grid grid-cols-1 lg:grid-cols-2 gap-6 py-5">
                    {
                         (feedsFromCategory?.filter(item => (parseInt(item?.is_active||0) === 1) && (parseInt(item?.approval||0) === 1)) || []).map(feed => (
                            <ListMenu
                                urlKey={feed.url_key}
                                id={feed.id}
                                key={feed.id}
                                title={feed.title}
                                image={feed.feed_type==='Internal Blog'?null:feed.image}
                                description={feed.description}
                                feedType={feed.feed_type}
                                url={feed.feed_type==='Internal Blog'?null:feed.url}
                            />
                         ))       
                    }
                </div>
                <div className="w-1/3 flex-col items-center h-full sticky top-0 hidden lg:flex md:flex mx-4 py-6">
                    <SearchBar 
                        placeholderText="What you need assistance for ?"
                        data={feeds}
                    />                    
                    {
                    subCategories.length ?
                    (                        
                            <CategorySlide
                                boxTitle={'Sub Categories'}
                                data={subCategories}
                            />                     
                    ) : <CategorySlide />
                    }
                    <Footer />
                </div>                
            </div>
            {/* {
                subCategories.length ? 
                (<div className="w-full flex items-center justify-center py-6">
                    <SubMenus
                        title="Sub Categories"
                        data={subCategories}
                    />
                </div>) : null
            }
            {
                feedsFromCategory.length ? (
                    <div className="w-full flex items-center justify-center py-6">
                        <ListMenu 
                            title="Feeds"
                            data={feedsFromCategory}
                        />
                    </div>
                )
                : null
            } */}
        </div>
    )
}

export default Category
