import React from 'react'
import moment from 'moment'
import assistanceIcon from '../../assets/img/icon.png'
import { validateURL } from '../../utils/Helper'

function TopFeedBox({ urlKey, image=null, title, category, created, domain, position, id }) {
    return (
        <div key={position} className="w-full flex items-start">                
                    <div className="flex flex-col items-start mx-2 mt-2">
                        <div className="flex items-center">
                            <img
                                className="h-4 rounded"                                
                                src={validateURL(image) ? image : assistanceIcon}
                                alt="logo or icon"
                            />
                            <p className="text-md font-semibold text-sm px-2">
                                {category}
                            </p>
                        </div>
                        <div className="flex flex-col items-start mt-1">
                            <a 
                                title={title}
                                href={`/${urlKey}`}
                                className="text-md font-bold primary-color text-left cursor-pointer topFeedBox__title"
                            >{title}                      
                            </a>
                            <div className="flex items-center">
                                <p className="text-gray-600 text-xs py-1">
                                    {moment(created).format('MMM DD')}
                                </p>
                                <span className="px-2">·</span>
                                <p className="text-gray-600 text-xs py-1 cut__me">
                                    {domain || 'assistance.org'}
                                </p>
                            </div> 
                        </div>
                    </div>
        </div>
    )
}

export default TopFeedBox
