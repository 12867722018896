import React from 'react'
import { Skeleton } from 'antd'

function PreLoaderList() {
    return (
        <>
            <Skeleton loading={true} active className="lg:px-16">
                                    <div>
                                        <h4>Assistace.org Sample Title</h4>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
                                </Skeleton>
                                <Skeleton loading={true} active className="lg:px-16 mt-3">
                                    <div>
                                        <h4>Assistace.org Sample Title</h4>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
                                </Skeleton>
                                <Skeleton loading={true} active className="lg:px-16 mt-3">
                                    <div>
                                        <h4>Assistace.org Sample Title</h4>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
                                </Skeleton>
                                <Skeleton loading={true} active className="lg:px-16 mt-3">
                                    <div>
                                        <h4>Assistace.org Sample Title</h4>
                                        <p>
                                        This is just a simple test description for the pre loader Skeleton
                                        used here dont care about this piece of text.
                                        </p>
                                    </div>                                
            </Skeleton>
        </>
    )
}

export default PreLoaderList
