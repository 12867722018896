import React, { useEffect, useState } from 'react'
import { Layout, Menu, Dropdown, message, Spin } from 'antd'
import '../stylesheets/Dashboard.css'
import { DownOutlined } from '@ant-design/icons'
import {    
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
  } from "react-router-dom"
import Home from '../components/Admin/Home'
import Roles from '../components/Admin/Utilities/Roles'
import Permissions from '../components/Admin/Utilities/Permissions'
import FeedTypes from '../components/Admin/Utilities/FeedTypes'
import Keywords from '../components/Admin/Utilities/Keywords'
import Account from '../components/Admin/Account'
import Users from '../components/Admin/Users'
import { useDataLayerValue } from '../DataLayer'
import apiInstance from '../api';
import { addKeyToData } from '../utils/Helper'
import SiteSettings from '../components/Admin/SiteSettings'
import logo from '../assets/img/logo.png'

const { Header, Content, Footer } = Layout;

function Dashboard() {
    let match = useRouteMatch();
    const history = useHistory();

    const [roleProgress, setRoleProgress] = useState(true);
    const [permissionProgress, setPermissionProgress] = useState(true);    
    const [feedTypesProgress, setFeedTypesProgress] = useState(true);

    const [{ user, token, 
            allRolesStatus, 
            allPermissionsStatus,
            allKeywordsStatus,
            allFeedTypesStatus }, dispatch] = useDataLayerValue();

    useEffect(() => {

        document.title = "Dashboard - Assistance";                   
        
        if(!token) history.push("/admin");                        
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[token]);    

    useEffect(() => {

        setRoleProgress(true);

        async function getAllRoles() { // shoots all the present roles from db to data layer
        apiInstance.get("/role")
        .then(response => {        
            if(response.data.status) {
            // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
            addKeyToData(response.data.data, "id");
            setRoleProgress(false);
            // DISPATCH TO DATA LAYER !!!
            dispatch({
                type: "SET_ALL_ROLES",
                roles: response.data.data
            });

            } else message.warning(response.data.data);
            setRoleProgress(false);
        }).catch(e => {
            setRoleProgress(false);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });

        }

        getAllRoles();
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allRolesStatus]);

    useEffect(() => {
        setPermissionProgress(true);

        async function getAllPermissions() { // shoots all the present permissions from db to data layer
        apiInstance.get("/permission")
        .then(response => {        
            if(response.data.status) {
            // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
            addKeyToData(response.data.data, "id");
            setPermissionProgress(false);
            // DISPATCH TO DATA LAYER !!!
            dispatch({
                type: "SET_ALL_PERMISSIONS",
                permissions: response.data.data
            });

            } else message.warning(response.data.data);
            setPermissionProgress(false);
        }).catch(e => {
            setPermissionProgress(false);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });

        }

        getAllPermissions();
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allPermissionsStatus]);    

    useEffect(() => {        
        async function getAllKeywords() {
            apiInstance.get("/keyword")
            .then(response => {        
                if(response.data.status) {
                // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
                addKeyToData(response.data.data, "id");                
                // DISPATCH TO DATA LAYER !!!
                dispatch({
                    type: "SET_ALL_KEYWORDS",
                    keywords: response.data.data
                });                
    
            } else message.warning(response.data?.data || 'something went wrong');
            }).catch(e => {    
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
        }

        getAllKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allKeywordsStatus]);

    useEffect(() => {
        setFeedTypesProgress(true);
        async function getAllFeedTypes() {            
            apiInstance.get("/feedtype")
            .then(response => {        
                if(response.data.status) {
                // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
                addKeyToData(response.data.data, "id");
                setFeedTypesProgress(false);
                // DISPATCH TO DATA LAYER !!!
                dispatch({
                    type: "SET_ALL_FEED_TYPES",
                    feedTypes: response.data.data
                });
    
            } else message.warning(response.data?.data || 'something went wrong');
            setFeedTypesProgress(false);
            }).catch(e => {
                setFeedTypesProgress(false);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
        }

        getAllFeedTypes();
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[allFeedTypesStatus]);

    const logOut = () => {
        // LOGIC FOR LOGGING OUT !!!
        sessionStorage.clear();
        dispatch({
            type: 'LOG_OUT'
        });
        history.push("/admin");
    }

    const mainMenu = (
        <Menu>
          <Menu.Item className="px-8">
            <Link to={`${match.url}/account`}
            >Account</Link>
          </Menu.Item>          
          <Menu.Item className="px-8">          
            <p                 
                onClick={logOut}
                className="cursor-pointer"
            >Log Out
            </p>
          </Menu.Item>          
        </Menu>
      );

    const utilitiesMenu = (
        <Menu className="mt-4">
            <Menu.Item className="px-8">
                <Link to={`${match.url}/utilities/roles`}
                >Roles</Link>
            </Menu.Item>
            <Menu.Item className="px-8">
                <Link to={`${match.url}/utilities/permissions`}
                >Permissions</Link>
            </Menu.Item>
            <Menu.Item className="px-8">
                <Link to={`${match.url}/utilities/keywords`}
                >Keywords</Link>
            </Menu.Item>
            <Menu.Item className="px-8">
                <Link to={`${match.url}/utilities/feedtypes`}
                >Feed Types</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout>

            <Header                 
                className="flex items-center justify-between"
                style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <div className="h-full flex items-start">                    
                    <Link to="/"
                        className="flex items-center bg-white" style={{height:'100%'}}
                    >
                        <img
                            alt="logo"
                            src={logo}
                            className="h-10 object-contain mx-1"
                        />
                    </Link>
                    <Menu theme="dark" mode="horizontal">
                        <Menu.Item key="1">
                            <Link to={match.url}
                            >Home</Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to={`${match.url}/users`}
                            >Users</Link>
                        </Menu.Item>    
                        <Menu.Item key="3">
                            <Dropdown overlay={utilitiesMenu}>
                                <p className="ant-dropdown-link text-white" onClick={e => e.preventDefault()}>
                                    Utilities <DownOutlined />
                                </p>
                            </Dropdown>
                        </Menu.Item>    
                        <Menu.Item key="4">
                            <Link to={`${match.url}/site-settings`}
                            >Site Settings</Link>
                        </Menu.Item>                    
                    </Menu>  
                </div>  
                <Dropdown overlay={mainMenu}>
                    <p className="ant-dropdown-link text-white cursor-pointer" onClick={e => e.preventDefault()}>
                        {user?.username||'dev'} <DownOutlined />
                    </p>
                </Dropdown>
            </Header>

            <Content className="site-layout w-full h-full flex items-center justify-center" style={{ padding: '0 50px' }}>
                <div className="site-layout-background w-full mt-24" style={{ padding: 24, minHeight: 550 }}>
                    {
                        roleProgress || permissionProgress || feedTypesProgress ?
                        <div className="w-full mt-20 flex flex-col items-center jusitfy-center"
                            ><h1 className="font-black text-3xl text-center w-full text-black py-4"
                            >Setting up environment</h1>
                            <Spin 
                                size="large"
                            />
                        </div>
                        :
                        <Switch>
                            <Route exact path={match.path}
                            > <Home />                           
                            </Route>
                            <Route path={`${match.path}/users`}
                            > <Users />
                            </Route>   
                            <Route path={`${match.path}/site-settings`}
                            > <SiteSettings />
                            </Route>   
                            <Route path={`${match.path}/utilities/roles`}
                            > <Roles />                     
                            </Route>
                            <Route path={`${match.path}/utilities/permissions`}
                            > <Permissions />                     
                            </Route>
                            <Route path={`${match.path}/utilities/feedtypes`}
                            > <FeedTypes />                     
                            </Route>
                            <Route path={`${match.path}/utilities/keywords`}
                            > <Keywords />                     
                            </Route>
                            <Route path={`${match.path}/account`}
                            > <Account />                           
                            </Route>                        
                        </Switch>
                    }
                </div>  
            </Content>

            <Footer style={{ textAlign: 'center' }}>Assistance ©2020 All Rights Reserved</Footer>

        </Layout>
    )
}

export default Dashboard
