/* eslint-disable */
import React,{ useEffect, useState } from 'react'
import { addKeyToData } from '../../utils/Helper'
import { Button, Table, Tag, Input, Space, message } from 'antd'
import { PlusOutlined, SearchOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import UserModal from './UserModal'
import apiInstance from '../../api'

function Users() {

    const [userData, setUserData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [assignModal, setAssignModal] = useState(false);    
    const [currentUser, setCurrentUser] = useState(null);
    const [load, setLoad] = useState(false);    
    const [postLoad, setPostLoad] = useState(false);

    useEffect(() => {    

        setLoad(true);
        async function getUsers() {
            apiInstance.get("/person/")
            .then(response => {
                if(response.data.status) {
                    addKeyToData(response.data.data, "id");
                    const newUsers = response.data.data.sort((personA, personB) => {
                        return new Date(personB.modified_at) - new Date(personA.modified_at);
                    });
                    setUserData(newUsers);
                    setLoad(false);
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");      
            });
        }

        getUsers();

    },[postLoad]);

    // ANT DESIGN METHODS
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    // ANT DESIGN METHODS
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);        
    };

    // ANT DESIGN METHODS
    const handleReset = clearFilters => {
        clearFilters();        
        setSearchText('');
    };

    // Table Columns Defined
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',            
            ...getColumnSearchProps('username')
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'id',
            ...getColumnSearchProps('role')
        },
        {
            title: 'Email',
            dataIndex: 'email_address',
            key: 'email_address'
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: is_active => (
                <Tag color={is_active ? 'green' : 'red'}>
                    {is_active ? 'active' : 'inactive'}
                </Tag>
            ),
            filters: [
                { text: 'active', value: 'active' },
                { text: 'inactive', value: 'inactive' } 
            ],
            onFilter: (value, record) => record.is_active === (value==='active' ? 1 : 0)
        },
        {
            title: 'Last Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: modified_at => {
                return moment(modified_at).format('lll');
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <p     
                        className="cursor-pointer primary-color"
                        onClick={() => openUpdateModal(true, record)}                                    
                    ><EditOutlined />
                    </p>
                    {/* <Popconfirm
                        onConfirm={() => deleteUser(record.id)}
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                    ><a><DeleteOutlined /></a> 
                    </Popconfirm>                     */}
                    <p
                        className="cursor-pointer primary-color"
                        onClick={() => openUserModal(true, record)}
                    ><UserAddOutlined /></p>                    
                </Space>
            )
        }
    ];

    /**
     * Used to handle the updatiion modal of a particular
     * feed item based on the selection
     * @param {boolean, array} e 
     */
    const openUpdateModal = (e, data) => {        
        setCurrentUser(data); // passing the selected user id
        setUpdateModalTrigger(e); // opening the modal          
    }

    const postCreation = (flag) => {
        setAddModalTrigger(flag);
        setPostLoad(!postLoad);
        setCurrentUser(null);
    }

    const postUpdation = (flag) => {
        setUpdateModalTrigger(flag);
        setPostLoad(!postLoad);
        setCurrentUser(null);
    }

    const postRolesUpdation = (flag) => {
        setAssignModal(flag);        
        setCurrentUser(null);
    }

    /**
     * Used to soft delete the user selected
     * from the table with a confirmation
     * @param {id} id 
     */
    const deleteUser = (id) => {
        
        apiInstance.put(`/person/delete/${id}`)
        .then(response => {
            if(response.data.status) {                             
                setPostLoad(!postLoad);
                message.success(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
                message.error(e.response.data.message);
                if(e.response.status === 401) {
                    sessionStorage.clear();
                    window.location.replace("/admin");
                }
            } else message.error("Internal Server Error");
        });

    }

    /**
     * Used to open the modal with role 
     * operations
     */
    const openUserModal = (flag, user) => {        
        setCurrentUser(user); // passing the selected user id
        setAssignModal(flag);
    }

    return (
        <div className="w-full h-full flex flex-col items-center">
            <div className="w-full flex items-center justify-between mb-4">
                <Button
                    onClick={() => setAddModalTrigger(true)}
                    type="primary"                    
                ><PlusOutlined/> Add
                </Button>                
            </div>
            <Table                     
                loading={load}
                className="w-full py-4"
                dataSource={userData}
                columns={columns}
            />
            {
                addModalTrigger && 
                <UserModal    
                    data={true}            
                    type="create"
                    modalTitle="Create User"
                    okLabel="Create"
                    trigger={addModalTrigger}
                    callTrigger={postCreation}
            />
            }
            {
                updateModalTrigger &&
                <UserModal
                type="update"
                data={currentUser}
                modalTitle="Update User"
                okLabel="Save"
                trigger={updateModalTrigger}
                callTrigger={postUpdation}
            />
            }
            {
                assignModal &&
                <UserModal
                data={currentUser}
                type="assign_role"                
                modalTitle="Modify Roles for user"
                okLabel="Save"
                trigger={assignModal}
                callTrigger={postRolesUpdation}
            />        
            }               
        </div>
    )
}

export default Users
