import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ type=null }) {
    return (
        <div className="w-full border-t border-gray-400 pt-4 flex-col items-start my-4 text-left flex">
            <div className="flex items-start">
                <Link to="/terms" className="hover:text-gray-700 underline text-gray-600 mr-6 py-1 text-sm">
                    Terms and Conditions
                </Link>                                                                
                <Link to="/contact" className="hover:text-gray-700 underline text-gray-600 mr-6 py-1 text-sm">
                    Contact Us
                </Link>                                
            </div>
            <div className="w-full flex items-start">
                <p 
                    onClick={() => window.location.replace('/about#about')}
                    className="cursor-pointer hover:text-gray-700 underline text-gray-600 mr-6 py-1 text-sm">
                    About Us
                </p>                
                <p 
                    onClick={() => window.location.replace('/about#mission')}
                    className="cursor-pointer hover:text-gray-700 underline text-gray-600 mr-6 py-1 text-sm">
                    Our Mission
                </p>                
            </div>
        </div>
    )
}

export default Footer
