import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { useDataLayerValue } from '../../DataLayer';

function SearchBar({ data=null, placeholderText }) {
    let query = new URLSearchParams(useLocation().search);

    const [{ feeds, categories }] = useDataLayerValue();

    const [searchResult, setSearchResult] = useState([]);    

    const [search, setSearch] = useState('');    
    // const [searchData, setSearchData] = useState([]);    

    useEffect(() => {
       if(query) setSearch(query.get('q'));       
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleChange = (e) => {                
        if(e.key === 'Enter') {
            
        }        
        setSearchResult(feeds.filter(item => 
            item?.title?.toLowerCase()
                .includes(search?.toLowerCase()||'') || 
            item?.name?.toLowerCase()
                .includes(search?.toLowerCase()||'') ||
            item?.keywords?.toLowerCase()
                .includes(search?.toLowerCase()||'')
        ))                
    }

    const searchNow = () => {        

    }

    // const searchNow = () => {
    //     history.push(`/search?q=${search}`);
    //     dispatch({
    //         type: 'SET_SEARCH_TRIGGER',
    //         status: !searchTrigger
    //     });
    // }

    return (
        <div className="w-full flex flex-col items-center lg:my-2 mb-4 lg:mb-0">
            <div className={`w-full flex items-center justify-between ${search?'rounded-t-lg': 'rounded-lg'} border-t border-l border-r ${!search && 'border-b'} border-gray-400 bg-white shadow-sm`}>                
                    <input                           
                        value={search||''}
                        onChange={e => setSearch(e.target.value||'')}
                        onKeyPress={handleChange}
                        onKeyDown={handleChange}
                        className={`w-full p-3 ml-2 text-md primary-color border-none bg-none shadow-none focus:outline-none rounded-lg`}
                        placeholder={placeholderText}
                    />                                            
                <SearchOutlined    
                    onClick={searchNow}                 
                    className="p-2 mr-2 text-lg cursor-pointer" 
                />
            </div>            
            {
                !!search && searchResult.length ? (
                    <div className="w-full relative flex flex-col px-5 border-b border-l border-r shadow-sm border-gray-400 overflow-x-hidden overflow-y-auto categorySlide">
                        {
                            searchResult.map(result => (
                                <div className="w-full border-b mb-1 flex flex-col items-start">                                                                            
                                    <Link
                                        to={`/article/${categories.filter(category => category.id === result?.category_id)[0]?.title}`}    
                                        className="ml-2 text-xs text-left py-1"
                                    >{categories.filter(category => category.id === result?.category_id)[0]?.title}                                        
                                    </Link>                                    
                                    <Link
                                        to={`/${result.url_key}`}
                                        className="p-2 text-md text-gray-700 text-left border-b cursor-pointer primary-color"
                                    >{result?.title}
                                    </Link>
                                </div>
                            ))
                        }                        
                    </div>
                ) : search ?
                (
                    <div className="w-full border-b border-l border-r mb-1 flex flex-col items-center text-center border-gray-400">                                                                            
                        <p className="p-1 text-gray-700">No Results Found</p>
                    </div>
                ) : null
            }
        </div>
    )
}

export default SearchBar
