import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDataLayerValue } from '../../DataLayer'
import { DownOutlined } from '@ant-design/icons'
import logo from '../../assets/img/logo.png'
import { Menu, Dropdown } from 'antd'

function Header() {    
    const [{ token, user }, dispatch] = useDataLayerValue();
    let location = useLocation();

    const logOut = () => {
        sessionStorage.clear();
        dispatch({
            type: 'LOG_OUT'
        });
        window.location.replace('/');
    }

    const mainMenu = (
        <Menu>
          <Menu.Item className="px-8">
            <Link to="/dashboard">
                Home
            </Link>
          </Menu.Item>          
          <Menu.Item className="px-8">          
            <p                 
                onClick={logOut}
                className="cursor-pointer"
            >Log Out
            </p>
          </Menu.Item>          
        </Menu>
    );

    const isActive = (path) => path === location.pathname;

    return (
        <div className="w-full flex items-center justify-between px-10 lg:px-24 py-5 bg-white shadow-sm border-b border-gray-300 sticky top-0 z-10">
            <Link 
                to="/"
                className="flex items-center">
                <img   
                    alt="logo"                 
                    className="h-10 object-contain mx-1"
                    src={logo}
                />
                {/* <h3                
                    className="font-extrabold text-2xl cursor-pointer primary-color"
                >assistance
                </h3> */}
            </Link>
            <div className="items-center hidden lg:flex md:flex">
                <Link
                    className={`px-4 primary-color py-2 font-medium text-md cursor-pointer`}
                    to="/"
                >{isActive('/')?<mark>Home</mark>:'Home'}         
                </Link>                
                {
                    !!token && (
                        <Dropdown overlay={mainMenu}>
                            <p className="ant-dropdown-link cursor-pointer" onClick={e => e.preventDefault()}>
                                {user?.username||'dev'} <DownOutlined />
                            </p>
                        </Dropdown>                        
                    )
                }
            </div>
        </div>
    )
}

export default Header
