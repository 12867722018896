import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SearchBar from '../components/User/SearchBar'
import SubMenus from '../components/User/SubMenus'
import apiInstance from '../api'
import { message } from 'antd'
import ListMenu from '../components/User/ListMenu'
import { useDataLayerValue } from '../DataLayer'
import Header from '../components/User/Header'

function Search() {
    let query = new URLSearchParams(useLocation().search);    

    const [{ searchTrigger }] = useDataLayerValue();

    // eslint-disable-next-line
    const [result, setResult] = useState([]);

    const [categories, setCategories] = useState([]);
    const [feeds, setFeeds] = useState([]);

    useEffect(() => {        
        async function getSearchResult() {
            apiInstance.get(`/homepage/search?${query}`)
            .then(response => {
                if(response.data.status) {                    
                    setResult(response.data.data);
                    setCategories(response.data.data.filter(c => c.title));
                    setFeeds(response.data.data.map(f => f));
                } else message.warning(response.data?.data||'Something Went Wrong!');
            }) 
            .catch(e => {
                console.log(e);
                if(e.response || e.response?.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
              });

        }
        getSearchResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchTrigger]);

    return (
        <div className="py-4">
            <Header />
            <SearchBar                
            />            
            {
                !feeds.length && !categories.length ?
                <h3 className="px-20 text-xl text-center w-full text-gray-600 py-10"
                >No Results Found
                </h3>
                : null
            }
            {
                categories.length ?
                <div className="px-20 py-10">
                    <h3 className="font-bold text-xl text-left text-black py-2"
                    >Categories Found <span className="bg-orange-500 rounded-md px-2 text-white font-bold">{categories.length}</span>               
                    </h3>
                    <SubMenus
                        type='search'
                        data={categories}
                    />
                </div>
                : null
            }
            {
                feeds.length ?
                <div className="px-20 py-4">
                    <h3 className="font-bold text-xl text-left text-black py-2"
                    >Feeds Found <span className="bg-orange-500 rounded-md px-2 text-white font-bold">{feeds.length}</span>               
                    </h3>
                    <ListMenu
                        data={feeds}
                    />
                </div>
                : null
            }            
        </div>
    )
}

export default Search
