import React, { useState, useEffect } from 'react'
import { Modal, Input } from 'antd'

function CrudModal({ utility, modalTitle, okLabel, processStatus=false, data=null, modalTrigger, callTrigger = ct => ct, type }) {
    
    const [name, setName] = useState('');          
    const [description, setDescription] = useState('');
    const [oldName, setOldName] = useState('');
    const [oldDescription, setOldDescription] = useState('');

    useEffect(() => {
        if(data) {
            setName(data?.name||''); // UPDATES THE NAME WITH THE PRESENT DATA            
            setOldName(data?.name||''); // UPDATES PREVIOUS STATE
            setDescription(data?.description||'');
            setOldDescription(data?.description||'');
        }
    },[data]);

    /**
     * Handling the positive action of the modal
     * based on the context it is used
     */
    const handleOk = () => {
        let payload = {};

        if(type === 'create') {
            payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                name,
                description: description || null
            };
        } else {
            payload = { // PAYLOAD BASED ON THE AVIALABLE DATA
                data: { name, description: description || null },
                id: data?.id || ''                
            };
        }

        callTrigger(false, type, payload);
        setName('');
        setDescription('');
        setOldName('');
        setOldDescription('');
    }

    /**
     * Handling the negative action of the modal
     * based on the users actions
     */
    const handleCancel = () => {
        callTrigger(false);
        setName('');
        setOldName('');
    }
   
    return (
        <Modal   
            maskClosable={false}         
            visible={modalTrigger}
            destroyOnClose={true}
            title={modalTitle}
            okText={okLabel}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{
                loading: processStatus,
                disabled: (((oldName === name) || !name) &&
                            ((oldDescription === description) || !name))                
            }}
        >     
        {
            utility === 'Permissions' ?
            <div>
                <Input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Name"
                    type="text"
                    className="mb-3"
                />
                <Input
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Description"
                    type="text"
                    className="mb-3"
                />
            </div>
             :
            <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name"
                type="text"
                className="mb-3"
            />    
        }    
        </Modal>
    )
}

export default CrudModal
